/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "../node_modules/@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "../node_modules/@ionic/angular/css/normalize.css";
@import "../node_modules/@ionic/angular/css/structure.css";
@import "../node_modules/@ionic/angular/css/typography.css";
@import '../node_modules/@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "../node_modules/@ionic/angular/css/padding.css";
@import "../node_modules/@ionic/angular/css/float-elements.css";
@import "../node_modules/@ionic/angular/css/text-alignment.css";
@import "../node_modules/@ionic/angular/css/text-transformation.css";
@import "../node_modules/@ionic/angular/css/flex-utils.css";



ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height .ion-page {

  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height .ion-page .inner-content {
  overflow: auto;
}

ion-modal.bottom-modal {
 
  bottom: 0;
  position: absolute;
  height: 70% ;
  top:  auto;

 
  --backdrop-opacity:0
}
@media screen and (min-width: 1023px) {
  ion-modal.bottom-modal {
    // --height:auto;
    

  }
}
ion-modal.full-height-modal {
  height: 100%;
  --width: 50%;
  --min-width: 50%;
  --max-width: 50%;
}
ion-modal.full-height-modal .modal-wrapper {
  position: absolute;
  bottom:0px;
  height: 100%;
  --width: 50%;
  --min-width: 50%;
  --max-width: 50%;
}

/* Fullscreen modal styles */
.fullscreen-modal {
  --width: 100vw;
  --min-width: 100vw;
  --max-width: 100vw;
  --height: 100vh;
  --min-height: 100vh;
  --max-height: 100vh;
  --border-radius: 0;
}

.fullscreen-modal .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.choose-prize-modal {
  --width: 100vw;
  --min-width: 100vw;
  --max-width: 100vw;
  --height: 100vh;
  --min-height: 100vh;
  --max-height: 100vh;
  --border-radius: 0;
}

.choose-prize-modal .modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.main{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.flex{
  flex:1
}
.disabled {
  color: lightgray
}

.img-disabled {
  filter: grayscale(100%) opacity(0.3)
}

.bw {
  filter: grayscale(100%)
}
.primary{
  color: var(--ion-color-primary);
}
.huerotate {
  filter: saturate(10) hue-rotate(220deg) brightness(100%) contrast(120%);
}
ion-content{
  height: 100%;
}
.bg-primary {
  background-color: var(--ion-color-primary);
}

.col {
  display: flex;
  flex-direction: column;
}
.my-col {
  display: flex;
  flex-direction: column;
}

.col-full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.col-toolbar-height {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
}

  .row {
    display: flex;
    flex-direction: row;
  }
  .my-row {
    display: flex;
    flex-direction: row;
  }
  .border-red {
    border:1px solid red
  }
  .border-green {
    border:1px solid green
  }
  .border-blue {
    border:1px solid blue
  }

.row-wrap {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}
.my-row-wrap {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.full-height {
  height: 100%;
}

.scroll {
  height: 100%;
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.flex-row {
  display: flex;
  flex-direction: column;
}

.flex-col {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.dark-backdrop {
  ion-backdrop {
    --backdrop-opacity: 1;
    background-color: rgba(2, 136, 209, 1); // #0288D1 in RGB format with 0.6 opacity

  }
  .no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  /* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
html, body {
  overscroll-behavior: none;
  padding-bottom: env(safe-area-inset-bottom);
}
}
.center-center{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body {
  padding-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
.accent {
  color: var(--ion-color-warning);
}
ion-header {
  padding-top: env(safe-area-inset-top);
}
.round-button{
border-radius: 50%;
display:flex;
align-items:center;
justify-content:center;
padding:5px;
}
ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

/* Custom modal styles */
.travel-destinations-modal {
  --backdrop-opacity: 0.6;
  --width: 90%;
  --height: 90%;
  --max-width: 800px;
  --border-radius: 16px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  
  .modal-wrapper {
    border-radius: 16px;
  }
}

/* Toast Notifications for Level Completion/Unlock */
.level-toast {
  --background: rgba(255, 255, 255, 0.95);
  --box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  --border-radius: 12px;
  --min-width: 300px;
  --max-width: 90%;
  
  .toast-header {
    font-size: 18px;
    font-weight: 600;
    color: var(--ion-color-dark);
    padding: 16px 16px 8px;
  }
  
  .toast-message {
    font-size: 16px;
    color: var(--ion-color-medium);
    padding: 0 16px 16px;
  }
  
  .toast-button {
    --color: var(--ion-color-primary);
    font-weight: 600;
  }
  
  &.level-completed {
    --background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(236, 255, 236, 0.95) 100%);
    
    .toast-header {
      color: var(--ion-color-success);
    }
    
    .toast-message {
      color: var(--ion-color-success-shade);
    }
  }
  
  &.level-unlocked {
    --background: linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(236, 246, 255, 0.95) 100%);
    
    .toast-header {
      color: var(--ion-color-primary);
    }
    
    .toast-message {
      color: var(--ion-color-primary-shade);
    }
  }
}

/* Animation for level completion/unlock toast */
@keyframes toast-slide-in {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.level-toast {
  animation: toast-slide-in 0.5s ease-out forwards;
}

/* Celebration animation for level completion */
@keyframes celebration {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.level-completed .toast-header,
.level-unlocked .toast-header {
  animation: celebration 1s ease-in-out;
}

/* Toast buttons styling */
.level-toast .toast-button {
  --border-radius: 20px;
  --padding-start: 16px;
  --padding-end: 16px;
  height: 36px;
  margin: 8px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Toast wrapper styling */
ion-toast.level-toast-wrapper {
  --width: auto;
  --max-width: 90%;
  --min-width: 300px;
}

/* Confetti animation for level completion */
@keyframes confetti-fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(100px) rotate(360deg);
    opacity: 0;
  }
}

.level-completed::before,
.level-completed::after,
.level-unlocked::before,
.level-unlocked::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  top: 0;
  animation: confetti-fall 1.5s ease-out forwards;
}

.level-completed::before {
  left: 25%;
  background-color: var(--ion-color-success);
  animation-delay: 0.2s;
}

.level-completed::after {
  left: 75%;
  background-color: var(--ion-color-warning);
  animation-delay: 0.5s;
}

.level-unlocked::before {
  left: 35%;
  background-color: var(--ion-color-primary);
  animation-delay: 0.3s;
}

.level-unlocked::after {
  left: 65%;
  background-color: var(--ion-color-secondary);
  animation-delay: 0.6s;
}

/* Additional confetti particles */
.level-toast.level-completed::before,
.level-toast.level-unlocked::before {
  box-shadow: 
    15px 10px 0 var(--ion-color-tertiary),
    -15px 15px 0 var(--ion-color-warning),
    25px -5px 0 var(--ion-color-success),
    -25px -10px 0 var(--ion-color-primary);
}

.level-toast.level-completed::after,
.level-toast.level-unlocked::after {
  box-shadow: 
    -10px 10px 0 var(--ion-color-secondary),
    10px 15px 0 var(--ion-color-danger),
    -20px -5px 0 var(--ion-color-tertiary),
    20px -10px 0 var(--ion-color-warning);
}

/* Custom modal styles */
.conversations-level-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  --backdrop-opacity: 0.8;
  --box-shadow: none;

  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}